<template>
    <div>
        <h2>{{ $t("tiers.menu_chevaux") }}</h2>
        <div class="box">
            <CustomTable
				ref="table"
                id_table="tiers_horse_online"
                :items="filtredHorse"
                :busy.sync="table_busy"
                primaryKey="tiershorse_id"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
				:columsAdd="addCaCols"
				:externSlotColumns="addCaCols"
                :base-filters="filters"
            >
				<template v-slot:[`custom-slot-cell(horse.set_ca_displayable)`]="{ data }">
					<b-form-checkbox
						class="custom-control custom-checkbox float-right mr-2"
						v-model="data.horse.horse_display_ca" 
						@input="switchDisplayCa(data.horse)"
						name="check-button" 
						switch
					>
					</b-form-checkbox>
				</template>
                <template v-slot:[`custom-slot-cell(horse.horse_wholesire)`]="{ data }">
                    <e-copybox :value="data.horse.horse_wholesire"></e-copybox>
                </template>
                <template v-slot:[`custom-slot-cell(horse.horse_transpondeur)`]="{ data }">
                    <e-copybox :value="data.horse.horse_transpondeur"></e-copybox>
                </template>
                <template v-slot:[`custom-slot-cell(parts_error)`]="{ data }">
                    <font-awesome-icon :id="'parts-error_' + data.tiershorse_horse" v-if="data.parts_error" :icon="['fas', 'exclamation-triangle']" />
                    <b-tooltip :target="'parts-error_' + data.tiershorse_horse" triggers="hover">
                        {{ $t("tiers.erreur_pourcentage_exists")}}
                    </b-tooltip>
                </template>
			</CustomTable>

            <b-modal ref="modalPension" hide-footer size="xxl">
						<template v-slot:modal-title>
							<template>
								{{ $t("horse.ajouter_pension") }}
							</template>
						</template>
						
						<PensionAjout
							ref="PensionAjout"
							:horse_ids="horse_ids"
							:add-or-edit-ready="add_ready"
						/>

						<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkFormPension" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
					</b-modal>

            <!-- Ajouter/Modifier un tier -->
            <b-modal ref="modal" hide-footer size="xl">
                <template v-slot:modal-title>
                    <template v-if="ajouter">
                        {{ $t("tiers.ajouter_tiers_horse") }}
                    </template>

                    <template v-if="modifier">
                        {{ $t("tiers.modifier_tiers_horse") }}
                    </template>
                </template>

                <HorseAjout 
                    :add-or-edit-ready.sync="addOrEditReady"
                    :disable-button.sync="disableButton"
                    ref="tiersHorseAjout"
                    :tiershorse_id="tiershorse_id"
                    :tiers="tiers"
                    :tiers_horse="tiers_horse"
                />

                <b-button v-if="ajouter && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
                <b-button v-if="modifier && addOrEditReady && !disableButton" class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.modifier") }}</b-button>
            </b-modal>

            <!-- Transfert des chevaux -->
            <b-modal ref="modalTransfert" hide-footer>
                <template v-slot:modal-title>
                    {{ $t("tiers.transferer_tiers_horse") }}
                </template>

                <label for="">{{ $t('tiers.tier') }} *</label>
                <SearchTiers 
					:tiers_selected.sync="tiers_transfert"
					:preselected="tiers_transfert"
				/>

                <b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkFormTransfert" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.transferer") }}</b-button>
            </b-modal>

            <HorseCloture ref="modal-cloture" @submit="reload"></HorseCloture>

			<ModalTiersSecondaire ref="modal-add-tiers-secondaire"></ModalTiersSecondaire>
        </div>

        <HorseHistorique ref="horseHistorique" :tiers_id="tiers.tiers_id" />
    </div>
</template>

<script type="text/javascript">
    import HorseMixin from "@/mixins/Horse.js"
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js";
    import TiersMixin from "@/mixins/Tiers.js"
    import Contract from "@/mixins/Contract.js"
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35'
    import HorseAjout from "@/components/Tiers/HorseAjout";
    import HorseHistorique from "@/components/Tiers/HorseHistorique";

    export default {
        name: "HorseOnline",
        mixins: [HorseMixin, TableAction, Navigation, TiersMixin, Contract],
        props: ['tiers'],
        data () {
            return {
                addOrEditReady: false,
                disableButton: false,
                processing: false,
                ajouter: false,
                modifier: false,
                tiershorse_id: null,
                tiers_horse: null,
                table_busy: true,
                tiers_transfert: null,
                horse_ids: null,
                tiershorse_ids_transfert: null,
                add_ready: false,
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
                },
                events_tab: {
                    'TableAction::goToAddTiersHorseHorse': () => {
                        this.addOrEdit()
                    },
                    'TableAction::goToEditTiersHorseHorse': (params) => {
                        this.addOrEdit(params.tiershorse_id)
                    },
                    'TableAction::goToDeleteTiersHorseHorse': (params) => {
                        this.$refs['modal-cloture'].open(params.horse_id, null, params.tiershorse_id)
                    },
					'TableAction::goToAddTiersHorseSecondaires': (params) => {
                        this.$refs['modal-add-tiers-secondaire'].open(params, null)
                    },
                    'DeleteTiersHorseHistoriqueHorseSuccess': (params) => {
                        this.loadHorseComponent()
                    },
					'TableAction::goToPdfTiersHorseHorse': (params) => {
                        this.loadPdf(params.horse_ids)
                    },
					'TableAction::goToTransferHorsesOtherTiers': (tiershorse_ids) => {
                        // this.openModalTransfert(tiershorse_ids)
                        this.tiershorse_ids_transfert = tiershorse_ids
                        this.$refs.modalTransfert.show()
                    },
					'TableAction::goToAddContract': async (params) => { 
                        this.contract_id = await this.addContractOnline();
                       	this.$router.push({
                        name: "ContractFormv2",
                        params: {
                            contract_id: parseInt(this.contract_id),
                            mare_id: parseInt(params[0].horse.horse_id),
                            tier_id: parseInt(params[0].tiershorse_tiers)
                        }})
						this.table = false
					},
                    'TableAction::goToAddPensionFromTiers': (horse_ids) => {
					    this.openModalAddPension(horse_ids)
				    },
                }
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                await this.loadHorseComponent()
            },
            async loadHorseComponent() {
				this.table_busy = true
				const tiers = await this.loadHorseAllCurrentPartsOnline(this.tiers.tiers_id)
                if(tiers) {
					this.tiers_horse = tiers.tiers_horse
                } else {
                    this.tiers_horse = null
                }

                this.table_busy = false
            },
            async addOrEdit(tiershorse_id) {
                this.processing = false
                this.addOrEditReady = false
                if(tiershorse_id) {
                    this.ajouter = false
                    this.modifier = true
                    this.tiershorse_id = tiershorse_id
                } else {
                    this.ajouter = true
                    this.modifier = false
                    this.tiershorse_id = null
                }

                this.$refs.modal.show()
            },
            async checkForm() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.tiersHorseAjout.checkForm()
                    if(result) {
                        this.$refs.modal.hide()
                        this.loadHorseComponent()
                        this.$refs.horseHistorique.loadHorseHistoriqueComponent()
                    } else {
                        this.processing = false
                    }
                }
            },
			switchDisplayCa(horse) {
				this.switchHorseCaVisibility(horse.horse_id, horse.horse_display_ca)
			},
            async reload() {
                await this.$sync.force(true)
                this.init_component()
				this.$refs.table.resetCachedItems()
                this.$refs.horseHistorique.loadHorseHistoriqueComponent()
            },
			loadPdf(horse_ids){
				this.generatePdfFicheDeDepart(horse_ids)
			},
            async checkFormTransfert(){
                if(this.tiers_transfert)
                {
                    await this.transfertHorse(this.tiershorse_ids_transfert, this.tiers_transfert.tiers_id)
                    this.$refs.modalTransfert.hide()
                    this.$refs.table.unselectAll()
                    this.successToast()
                    await this.loadHorseComponent()
                }
            },
            openModalAddPension(horse_ids) {
                this.horse_ids = horse_ids
                this.add_ready = true
                this.$refs.modalPension.show()
            },
            async checkFormPension() {
                if(!this.processing) {
                    this.processing = true
                    let result = await this.$refs.PensionAjout.checkForm()
                    if(result) {
                        this.$refs.modalPension.hide()
                    }
                    this.processing = false
                }
            },
        },
        computed: {
            filtredHorse: function() {
                return this.tiers_horse
            },
			hasDedicatedClientArea() {
				return this.$store.state.userAccess.hasDedicatedClientArea
			},
			addCaCols() {
                let external_slot_columns = [
                    'horse.horse_wholesire',
                    'horse.horse_transpondeur',
                    'parts_error'
                ]
				if(!this.hasDedicatedClientArea) return external_slot_columns;
				external_slot_columns.push(['horse.set_ca_displayable'])
                return external_slot_columns
			},
            filters() {
                let filters = {
                    horse_not_inactive: {
                        column: 'horse.horse_inactive',
                        operator: 'isEqualTo',
                        value: 0
                    }
                }

                return filters
            }
        },
        components: {
            CustomTable,
            LoadingSpinner,
            HorseAjout,
            HorseHistorique,
            HorseCloture : () => import('@/components/Tiers/HorseCloture'),
			ModalTiersSecondaire: () => import('@/components/Horse/ModalTiersSecondaire'),
			SearchTiers : () => import('@/components/Contract/SearchTiers'),
            PensionAjout : () => import('@/components/Horse/PensionAjout'),
        }
    }
</script>
